import React from 'react';
import _ from 'lodash';
import Img from 'gatsby-image';

const ProgressiveImageContainer = ({ image, alt, className, imgStyle }) =>
  typeof image === 'string' ? (
    <img className={className} style={imgStyle} src={image} alt={alt} />
  ) : _.get(image, ['childImageSharp', 'fluid']) ? (
    <Img
      className={className}
      imgStyle={imgStyle}
      fluid={_.get(image, ['childImageSharp', 'fluid'])}
      alt={alt}
    />
  ) : (
    <img
      className={className}
      style={imgStyle}
      src={_.get(image, ['publicURL'], '')}
      alt={alt}
    />
  );

export default ProgressiveImageContainer;
