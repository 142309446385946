module.exports = {
  siteTitle: 'Rafaelian Psychiatry LLC', // Site title.
  siteTitleAlt: 'Rafaelian Psychiatry', // Alternative site title for SEO.
  siteLogo: '/icons/logo_transparent.png', // Logo used for SEO and manifest.
  siteUrl: 'https://RafaelianPsychiatry.com', // Domain of your website without pathPrefix.
  icon: 'src/assets/images/logo_transparent.png',
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Telemedicine Practice.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  google: {
    analyticsTrackingID: 'UA-179507779-1',
    optimizeTrackingID: '',
    optimizeExperimentID: '',
    optimizeVariationID: '',
  },
  googleTagManagerID: 'UA-179507779-1', // GTM tracking ID.
  disqusShortname: 'rafaelian-psychiatry-blog', // Disqus shortname.
  userName: 'Olga Rafaelian',
  userTwitter: 'drrafaelian',
  userLocation: 'Naples, FL',
  userDescription: '',
  copyright: 'Copyright © Rafaelian Psychiatry LLC 2022. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#8edcd0', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  cookieConsent:
    'This website uses cookies which are used to collect anonymous information to improve your browsing experience and for analytics and metrics.', // @TODO Add GDPR Cookie Consent
};
