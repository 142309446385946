import React from 'react';

const Footer = (props) => {
  const { copyright } = props;

  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <p>{copyright}</p>
          <p>
            Built with &#10084;&#65039; by{' '}
            <a href="http://Widdletechinc.com">Widdle Technology Inc.</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
